
import {defineComponent, ref, watch} from 'vue'
import router from "@/router";
import {RouteName} from "@/router/types";
import {forgotPassword} from "@/firebase/auth";
import {useAppStore} from "@/store/app/AppStore";
import {TYPE} from "vue-toastification";

export default defineComponent({
  name: "ForgotPassword",
  setup() {
    const appStore = useAppStore()
    const email = ref<string | null>(null)
    const toHomePage = () => router.push({name: RouteName.HOME})
    const counter = ref(5)
    const errorCodes: Record<string, string> = {
      'auth/invalid-email': 'Vigane e-mail',
      'auth/user-not-found': 'Kasutajat ei leitud',
      'auth/missing-android-pkg-name': 'Miskit läks valesti',
      'auth/missing-continue-uri': 'Miskit läks valesti',
      'auth/missing-ios-bundle-id': 'Miskit läks valesti',
      'auth/unauthorized-continue-uri': 'Miskit läks valesti'
    }
    const emailSent = ref(false)
    const onForgotPassword = async () => {
      if (!email.value) return
      try {
        await forgotPassword(email.value)
        emailSent.value = true
        setInterval(() => counter.value -= 1, 1000)
      } catch (e: any) {
        const code: string = e.code
        appStore.showToast(TYPE.ERROR, code in errorCodes ? errorCodes[code] : 'Miskit läks valesti');
      }
    }

    watch(
        () => counter.value,
        (newValue) => {
          if (newValue <= 0) toHomePage()
        })
    return {toHomePage, email, onForgotPassword, emailSent, counter}
  }
})
